import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from '@ionic/storage-angular';
import { UserSettingsService } from './user-settings.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(
    private translate: TranslateService,
    private storage: Storage,
    private service: UserSettingsService
  ) {}

  getDefaultLanguage() {
    let language = this.translate.getDefaultLang();
    if (!language) {
      // language = this.translate.getBrowserLang();
      language = 'FR';
      language = language.toUpperCase();
      this.setLanguage(language);
    }
    language = language.toUpperCase();
    return language;
  }

  setLanguage(language) {
    if (!language) {
      // language = this.translate.getBrowserLang();
      language = 'FR';
    }
    language = language.toUpperCase();
    this.translate.use(language).subscribe((res: any) => {
      this.storage.set('language', language).then(() => {
        // console.log('Language set to ' + language);
        this.storage.get('user').then((res: any) => {
          // console.log(res);
          if(res !== "guest"){
            let req = {
              id: res.settings,
              language: language,
            };
            this.service.update_settings(req).subscribe((res) => {
              // console.log(res);
            });
          }

        });
      });
    });
    this.translate.setDefaultLang(language);
    let lng = language == 'ZH' ? 'zh-cn' : language;
    moment.locale(lng);
  }

  setLanguageFromStorage() {
    this.storage.get('language').then((lang: any) => {
      if (lang) {
        let language = lang;
        this.translate.use(language);
        this.translate.setDefaultLang(language);
      }
    });
  }

  tr(key): string {
    return this.translate.instant(key);
  }
}
