// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-fab {
  margin: 40px 20px;
}

ion-popover {
  --height: auto;
  --width: 400px;
}

.content_popover {
  padding: 10px;
  margin-bottom: 100px;
}
.content_popover .message_wapper {
  display: flex;
  align-items: center;
}
.content_popover .message_wapper .bot-icon {
  width: 20px;
}

.user_message {
  display: flex;
  justify-content: end;
}

.footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10000;
  background-color: #fff;
}
.footer ion-icon {
  color: var(--ion-color-primary);
  font-size: 25px;
  padding: 20px;
  cursor: pointer;
}
.footer textarea {
  display: block;
  width: 100%;
  max-height: 200px;
  margin: 0;
  padding: 15px 85px 15px 20px;
  background-color: transparent;
  border: none;
  line-height: 1.4;
  font-size: 15px;
  resize: none !important;
  overflow-x: hidden;
  overflow-y: auto;
  word-wrap: break-word;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  scrollbar-width: thin;
  scrollbar-color: #babac0 transparent;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
