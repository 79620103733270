// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiKey: "AIzaSyAVjuiDUAEblF5nVderoHhMIxgHJAmGTm0"
  apiKey: "AIzaSyA7TzwiE66PA3O23ybcR6QYqHbblsRmxWg",
  api_link:"https://api.renty.com.co",
  // toke:"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"
  // token2: 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
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
